@import "../utils/vars";

.timesheet-report-accordion {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #E8E9F4;
    padding-bottom: 16px;
    padding-top: 16px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    &--title {
      display: inline-block;
      margin-right: 5px;
      font-family: $openSansBold;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #4C5560;
    }

    &--icon {
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: -1px;

      i {
        font-size: 7px;
        color: $violet;
      }
    }

    &--date {
      font-family: $openSansRegular;
      font-style: normal;
      font-size: 13px;
      line-height: 18px;
      color: #4C5560;
    }

    &--cell {
      padding-right: 10px;
    }

    &--error {
      display: inline-flex;
      align-items: center;
      background-color: rgba(220, 112, 112, 0.1);
      border: 1px solid $red-color;
      border-radius: 4px;
      padding: 5px 10px;
      font-family: $openSansRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      color: #4C5560;
      @media screen and (max-width: 600px) {
        display: flex;
        width: 100%;
        margin-top: 5px;
        justify-content: center;
      }

      img {
        display: inline-block;
        width: 14px;
        margin-right: 5px;
      }
    }

    &.active {
      .timesheet-report-accordion {
        &__head {
          &--icon {
            transform: rotate(180deg);
            top: 1px;
          }
        }
      }
    }
  }

  &__body {
    padding-top: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .form-group {
      width: 50%;
      padding-right: 10px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .overnight {
      padding-top: 10px;
    }
  }

  &__times {
    padding-top: 8px;
    padding-bottom: 32px;
    border-bottom: 1px solid #E8E9F4;
    width: 100%;
  }

  &__title {
    font-family: $openSansBold;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4C5560;
    padding-bottom: 8px;

    &.required {
      &:after {
        content: '*';
        display: inline;
        font-family: $openSansBold;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $red-color;
        position: relative;
      }
    }
  }
}

app-timesheet-specify-working-time {
  .time-picker-select {
    .input-container {
      border-radius: 0 !important;
    }
  }
}

.timesheet-report-accordion {
  &__body {
    app-timesheet-specify-working-time {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .sub-header {
        display: none;
      }

      .schedule-day {
        width: calc(50% - 16px);
        flex-wrap: wrap;
        @media screen and (max-width: 700px) {
          width: 100%;
          max-width: 450px;
        }
        @media screen and (max-width: 600px) {
          margin-bottom: 30px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 27px 8px 0;

          .flex-row {
            &__item-name {
              width: auto;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
