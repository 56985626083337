@import "../utils/vars";

app-incidents,
app-patients-incident-report,
app-admissions,
app-incidents-attachments,
app-patients-docs,
app-patients,
app-note-list,
app-documents-history,
app-red-flags,
app-patients-red-flags,
app-medical,
app-notes,
app-medical-documents-history {
  .slide-container {
    position: fixed;
    z-index: 55;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: -webkit-fill-available;

    &__overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 54;
      background: rgba(0, 0, 0, 0.3);
      transition: $transition;
    }

    &__title {
      font-size: 16px;
      line-height: 22px;
      font-family: $openSansSemiBold;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #494A58;
      margin-bottom: 50px;
    }

    &__body {
      position: fixed;
      z-index: 10;
      right: 0;
      top: 0;
      bottom: 0;
      width: 544px;
      height: 100%;
      background: $white-color;
      transform: translateX(544px);
      padding: 36px 15px 30px 30px;
      @media screen and (max-width: 767px) {
        padding-left: 15px;
      }
      @media screen and (max-width: 530px) {
        width: 100%;
        transform: translateX(100%);
      }

      .close {
        position: absolute;
        right: 20px;
        z-index: 20;
        top: 20px;
        color: #9D9EA8;
        cursor: pointer;
        padding-left: 5px;
        padding-right: 5px;
        background-color: $white-color;
      }
    }

    &__content {
      height: 100%;
      overflow: hidden;
      padding-right: 15px;
      @media screen and (max-width: 767px) {
        padding-right: 0;
      }
    }

    .ps {
      &__rail-y {
        display: none !important;
      }
    }

    &.show {
      display: block;

      .slide-container {
        &__body {
          transform: translateX(0);
        }
      }
    }

    &.full {
      width: 100%;

      .slide-container {
        &__body {
          width: 100%;
          padding: 0;
        }

        &__content {
          padding: 21px 0 40px 0;
        }

        &__title {
          padding: 0 10px 0 53px;
          @media screen and (max-width: 1200px) {
            padding: 0 20px 0 23px;
          }
          @media screen and (max-width: 767px) {
            padding: 0 15px;
          }
        }
      }

      .notes-list {
        .slide-container {
          &__title {
            margin-bottom: 30px;
          }
        }

        &__add {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 30px;
          padding-bottom: 45px;
          padding-right: 10px;
          @media screen and (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        &__data {
          overflow-y: auto;
        }

        &__item {
          margin-bottom: 30px;

          .item-top-row {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &-name {
            font-size: 12px;
            line-height: 17px;
            font-family: $openSansSemiBold;
            color: #4C5560;
          }

          &-controls {
            button {
              margin-left: 15px;
              color: $violet;
              font-size: 16px;
            }
          }

          &-date {
            width: 100%;
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #4C5560;

            .time {
              color: #9D9EA8;
              display: inline-flex;
              margin-left: 5px;
            }
          }

          &-option {
            height: 30px;
            background: #F1F1F1;
            border-radius: 3px;
            padding: 0 13px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: $openSansSemiBold;
            font-size: 13px;
            line-height: 18px;
            color: #4C5560;
            margin-bottom: 28px;
          }

          .item-text {
            font-size: 12px;
            line-height: 17px;
            color: #4C5560;
            overflow-wrap: anywhere;
          }
        }
      }
    }
  }
}

app-incidents,
app-patients-incident-report,
app-admissions,
app-patient-doc-period,
app-patients,
app-medical,
app-medical-documents-history,
app-note-list,
app-red-flags,
app-notes,
app-patients-red-flags {
  .notes-list {
    padding-right: 10px;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }

    .slide-container {
      &__title {
        margin-bottom: 30px;
      }
    }

    &__add {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
    }

    &__data {
      overflow-y: hidden;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &__item {
      margin-bottom: 56px;
      width: calc(100% / 3);
      border-right: 1px solid #F4F0F0;
      padding: 3px 36px 3px 53px;
      @media screen and (max-width: 1200px) {
        padding: 3px 20px 0 23px;
      }
      @media screen and (max-width: 1024px) {
        width: 50%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        border-right: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
      @media screen and (max-width: 530px) {
        margin-bottom: 30px;
        border: 0;
      }

      &:nth-child(3n) {
        border-right: 0;
        padding-right: 10px;
        @media screen and (max-width: 1024px) {
          border-right: 1px solid #F4F0F0;
        }
        @media screen and (max-width: 767px) {
          border-right: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &:nth-child(even) {
        @media screen and (max-width: 1024px) {
          border-right: 0;
        }
      }

      .item-top-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-name {
        font-size: 12px;
        line-height: 17px;
        font-family: $openSansSemiBold;
        color: #4C5560;
      }

      &-controls {
        button {
          margin-left: 15px;
          color: $violet;
          font-size: 16px;
        }
      }

      &-date {
        width: 100%;
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #4C5560;

        .time {
          color: #9D9EA8;
          display: inline-flex;
          margin-left: 5px;
        }
      }

      .item-text {
        font-size: 12px;
        line-height: 17px;
        color: #4C5560;
        overflow-wrap: anywhere;
      }
    }
  }
}

app-incidents-attachments,
app-patients-docs,
app-documents-history,
app-medical,
app-medical-documents-history {
  .notes-list {
    .slide-container {
      &__title {
        margin-bottom: 30px;
      }
    }

    &__add {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
    }

    &__data {
      overflow-y: auto;
    }

    &__item {
      margin-bottom: 30px;

      .item-top-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-name {
        font-size: 12px;
        line-height: 17px;
        font-family: $openSansSemiBold;
        color: #4C5560;
      }

      &-controls {
        button {
          margin-left: 15px;
          color: $violet;
          font-size: 16px;
        }
      }

      &-date {
        width: 100%;
        margin-bottom: 15px;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #4C5560;

        .time {
          color: #9D9EA8;
          display: inline-flex;
          margin-left: 5px;
        }
      }

      &-option {
        height: 30px;
        background: #F1F1F1;
        border-radius: 3px;
        padding: 0 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $openSansSemiBold;
        font-size: 13px;
        line-height: 18px;
        color: #4C5560;
        margin-bottom: 28px;
      }

      .item-text {
        font-size: 12px;
        line-height: 17px;
        color: #4C5560;
        overflow-wrap: anywhere;
      }
    }
  }
}

app-patients-docs {
  .slide-container {
    &__body {
      //overflow-y: hidden;
      &.timesheet {
        width: 402px;
        @media screen and (max-width: 530px) {
          width: 100%;
          transform: translateX(100%);
        }
      }
    }

    &__content {
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.timesheet {
      padding-left: 0;
      padding-right: 0;
      width: 402px;
    }

    &.show {
      .slide-container {
        &__body {
          transition: all 0.4s ease;
        }
      }
    }

    .error {
      border-bottom: 0 !important;
    }
  }
}

app-create-note {
  .modal {
    transition: $slideSlideAnimateDue;
    transform: translateX(150%);

    &.open {
      transform: translateX(0);
    }
  }

  .scroll-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
    @include scrollbar($scrollBarColor, transparent);
    @media screen and (max-width: 767px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.slide-animate {
  background-color: $white-color;
  transition: $slideSlideAnimateDue !important;
  transform: translateX(150%);
  position: fixed;
  z-index: 55;
  right: 0;
  top: 0;
  bottom: 0;
  width: 544px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  &.is-full {
    width: 90%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &.open {
    transform: translateX(0);
  }
}
