$openSansRegular: 'OpenSans-Regular', sans-serif;
$openSansSemiBold: 'OpenSans-SemiBold', sans-serif;
$openSansBold: 'OpenSans-Bold', sans-serif;

// Notifications
$application_updated: #32B294;
$application_updated_BG: #E8FAF6;
$application_signed: #969BE0;
$application_signed_BG: #F5F5FF;
$attachment_added: #BE86F7;
$attachment_added_BG: #F9F2FF;
$application_assigned: #F5788F;
$application_assigned_BG: #FFF2F4;
$note_added: #F2C87E;
$note_added_BG: #FCF4E6;
$attention_BG: #FFF2EB;
$success_BG: #E6FAE8;
$comment_BG: #FFF0FC;
// END Notifications

$white-color: #ffffff;
$black-color: #000000;
$blackOpacityColor: rgba(0, 0, 0, 0.4);
$grey-light: #BABABA;
$violet: #6469b7;
$violet-hover: #4a4f9a;
$violet-dark: #282d75;
$green-color: #74A441;
$green-color-hover: #6a8c4e;
$red-color: #dc7070;
$yellow-color: #d4cc4c;
$red-color-hover: #c14d4d;
$disableButtonBackground: #B1B2BC;
$hoverOpacity: 0.8;
$gray-color: #4C5560;
$neutralColor: #4B5263;
$neutralHoverColor: darken($neutralColor, 5%);

$mbNewEmployeeForm: 24px;
$drop-box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.16);
$box-shadow-table: 0 2px 12px 0 rgba(46, 55, 65, 0.07);
$documentBorder: rgba(0, 0, 0, .2);
$scrollBarColor: rgba(76, 85, 96, 0.4);
$transition: all ease 0.15s;
$slideSlideAnimateDue: transform 0.35s ease-in-out;

$pageWidth: 1280px;
// Apple iPhone 5 Media Queries
$iPhone5Height: 568px;
